import React, { useRef, useEffect } from 'react'
import Image1 from '../images/products_et_01.png'
import Image2 from '../images/products_et_02.png'
import Image3 from '../images/products_et_03.png'

const EtHeroImage = () => {

  const image1Ref = useRef(null)
  const image2Ref = useRef(null)
  const image3Ref = useRef(null)

  useEffect(() => {
    let timer1, timer2
    image1Ref.current.src = Image1
    image1Ref.current.onload = () => {
      image1Ref.current.style.opacity = 1
      timer1 = setTimeout(() => {
        Object.assign(image2Ref.current.style, {
          transform: 'none',
          opacity: 1
        })
      }, 750)
      timer2 = setTimeout(() => {
        Object.assign(image3Ref.current.style, {
          transform: 'translate3d(0, -50%, 0)',
          opacity: 1
        })
      }, 1750)
    }
    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
    }
  })

  return (
    <div className='relative overflow-hidden'>
      <div style={{ paddingBottom: '100%' }} />
      <img
        ref={image2Ref}
        src={Image2}
        alt=''
        style={{ opacity: 0, transform: 'translateY(10%)' }}
        className='absolute w-full h-full top-0 left-0 transition duration-500'
      />
      <img
        ref={image1Ref}
        alt=''
        style={{ opacity: 0 }}
        className='absolute w-full h-full top-0 left-0 transition-opacity duration-300'
      />
      <img
        ref={image3Ref}
        src={Image3}
        alt=''
        style={{ opacity: 0, transform: 'translate3d(25%, -50%, 0) scale(90%)' }}
        className='absolute w-full h-full top-1/2 left-0 transition duration-500'
      />
    </div>
  )
}

export default EtHeroImage
