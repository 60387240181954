import * as React from 'react'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import ProductsSubnav from '../../components/products_subnav'
import EsTeaser from '../../components/es_teaser'
import HeaderSpacer from '../../components/header_spacer'
import { StaticImage } from 'gatsby-plugin-image'
import Container from '../../components/container'
import Button from '../../components/button'
import { Link as GatsbyLink } from 'gatsby'
import DemoModal from '../../components/demo_modal'
import NumberedScroll from '../../components/numbered_scroll'
import FullyLoadedImage1 from '../../images/fullyloaded_et_VIN-barcode-scanning.png'
import FullyLoadedImage2 from '../../images/fullyloaded_et_Photo-uploads.png'
import FullyLoadedImage3 from '../../images/fullyloaded_et_Firm-offers.png'
import EtHeroImage from '../../components/et_hero_image'

const TradeInAppraisalPage = () => (
  <Layout activeNav={true}>
    <Seo
      title='Trade-In Appraisal'
      description='Express Trade uses mobile-first technology to bring transparency and trust to the trade-in appraisal process. It’s available as an Express Storefront add-on or as a standalone solution for consumers who strictly want to sell their car.'
    />
    <HeaderSpacer dark />
    <ProductsSubnav />
    <div className='flex flex-col justify-center relative' style={{ minHeight: 675 }}>
      <StaticImage
        src='../../images/et_hero_bg.jpg'
        alt=''
        placeholder='blurred'
        className='absolute inset-0'
      />
      <Container className='relative'>
        <div className='pt-4 md:pt-0 grid items-center md:gap-3 md:grid-cols-2 lg:gap-5 text-center md:text-left'>
          <div>
            <p className='text-gray uppercase font-semi-bold text-base tracking-wide leading-none'>
              Trade-in Appraisal
            </p>
            <h1 className='mt-0 font-semi-bold text-4vw tracking-tight' style={{ marginLeft: '-.08em' }}>
              Express Trade<sup className='tm font-light'>®</sup>
            </h1>
            <p className='text-base sm:text-md lg:text-lg sm:leading-relaxed mb-1/2em'>
              Express Trade uses mobile-first technology to bring transparency and trust to the trade-in appraisal process. It’s available as an Express Storefront add-on or as a standalone solution for consumers who strictly want to sell their car.
            </p>
            <p className='italic text-base sm:text-base lg:text-md text-gray'>
              Express Storefront Required
            </p>
            <div className='mt-3 sm:mt-4'>
              <DemoModal>
                <Button
                  variant='dark'
                  minWidth={true}
                  children='Request Demo'
                />
              </DemoModal>
            </div>
          </div>
          <div>
            <EtHeroImage />
          </div>
        </div>
      </Container>
    </div>
    <div className='Section pt-0'>
      <Container>
        <div className='grid gap-3 sm:gap-5 sm:grid-cols-2 lg:grid-cols-3 leading-relaxed'>
          <div>
            <h2 className='font-light text-xl sm:text-2xl mt-0'>
              <img
                alt=''
                src='https://media.roadster.com/dealersales/ET_Icon_1.1.svg'
                height='82'
                width='66'
                className='block mb-2'
                style={{ marginLeft: '-.45em' }}
              />
              Mobile first
            </h2>
            <p className='text-base sm:text-md mb-0'>
              People use smartphones for practically everything. Why not use them to automatically capture trade-in info? Our vehicle appraisal tool helps foster transparency and trust. 
            </p>
          </div>
          <div>
            <h2 className='font-light text-xl sm:text-2xl mt-0'>
              <img
                alt=''
                src='https://media.roadster.com/dealersales/ET_Icon_2.1.svg'
                height='82'
                width='66'
                className='block mb-2'
                style={{ marginLeft: '-.27em' }}
              />
              More control
            </h2>
            <p className='text-base sm:text-md mb-0'>
              Express Trade enables you to centralize the vehicle-appraisal process and, unlike other vehicle trade-appraisal solutions, it gives you complete control over what you offer your customers. 
            </p>
          </div>
          <div>
            <h2 className='font-light text-xl sm:text-2xl mt-0'>
              <img
                alt=''
                src='https://media.roadster.com/dealersales/ET_Icon_3.svg'
                height='82'
                width='66'
                className='block mb-2'
                style={{ marginLeft: '-.2em' }}
              />
              Less time
            </h2>
            <p className='text-base sm:text-md mb-0'>
              We don’t call it Express Trade for nothing. Our trade-in tool makes it quick and easy to capture and assess a customer’s vehicle info, so you can make them a firm offer in minutes.
            </p>
          </div>
        </div>
      </Container>
    </div>
    <div className='Section Section--stripe pb-0'>
      <Container size='lg'>
        <h2 className='text-2xl sm:text-3xl font-light mt-0 text-center mb-em'>
          See What Modern Trade Looks Like.
        </h2>
        <div className='flex justify-center mb-3'>
          <DemoModal>
            <Button
              variant='dark'
              minWidth={true}
              children='Request a Demo'
            />
          </DemoModal>
        </div>
      </Container>
      <div className='flex justify-center'>
        <StaticImage
          alt=''
          src='../../images/et_car_crop.png'
          width={1440}
          placeholder='blurred'
        />
      </div>
    </div>
    <div className='Section'>
      <Container size='lg'>
        <h2 className='text-2xl sm:text-3xl font-light text-center mt-0'>
          Fully Loaded
        </h2>
        <p className='text-md text-center'>
          Our trade-in appraisal tool comes with everything you need to remotely value a vehicle and make customers a firm offer in minutes. 
        </p>
        <NumberedScroll
          items={[
            {
              title: 'VIN barcode scanning',
              desc: 'Customers can scan their VIN barcode with their smartphone or enter it manually. This way, you have all the vehicle’s build data right at your fingertips.',
              image: FullyLoadedImage1
            },
            {
              title: 'Photo uploads',
              desc: 'Customers can snap and share photos of their car with you using their smartphone, which builds trust and makes it easier for you to appraise the vehicle.',
              image: FullyLoadedImage2
            },
            {
              title: 'Simple e-valuation',
              desc: 'Customers only need to answer a few basic questions about their car, and all of the trade-in info is pushed directly to vAuto and your CRM for efficient assessment.'
            },
            {
              title: 'Firm offers',
              desc: 'Because it’s fast and easy to collect and review the customer’s trade-in info, you can make them a firm offer in minutes that everyone can feel good about.',
              image: FullyLoadedImage3
            },
            {
              title: 'Scheduled inspections + payouts',
              desc: 'Unlike other trade-in-appraisal tools, Express Trade gives you the final say about the vehicle’s worth. Confirm the car’s condition, transfer the title, and cut a check or put it towards a new car.'
            }
          ]}
        />
      </Container>
    </div>
    <div className='relative bg-dark-gray py-4'>
      <StaticImage
        alt=''
        src='../../images/customerstories_bg.jpg'
        placeholder='blurred'
        className='absolute inset-0'
      />
      <Container size='lg' className='relative'>
        <div className='text-white text-center'>
          <div className='font-semi-bold leading-none text-silver font-serif' style={{ fontSize: '6rem' }}>“</div>
          <div className='Testimonial' style={{ maxWidth: '30em' }}>
            During the inventory shortage, rather than just going to the auctions to acquire more inventory, we’re utilizing Express Trade. We target customers with ads that promote our ability to value their vehicle remotely and provide them with a firm offer in minutes. Using Express Trade has been a very easy way for the customer to send us pictures of their vehicle, provide a good description of it and allow my used-car managers to respond right away with a real number.
          </div>
          <div className='mt-3 text-md'>
            Aaron Hill, Managing Partner
          </div>
          <div className='text-md font-semi-bold'>
            Reed Auto Group
          </div>
          <div className='flex items-center leading-none mb-4 mt-5'>
            <span className='flex-1 border-t border-gray' />
            <span className='mx-2 uppercase tracking-wide text-base sm:text-md font-semi-bold'>
              More Customer Stories
            </span>
            <span className='flex-1 border-t border-gray' />
          </div>
        </div>
        <div className='grid gap-3 sm:grid-cols-2 md:grid-cols-3 sm:gap-4'>
          <GatsbyLink
            to='/customer_stories/sunautogroup'
            className='group w-full flex flex-col bg-white hover:shadow-lg transition-shadow'>
            <div className='p-2 sm:p-3'>
              <div className='font-semi-bold text-base sm:text-md mb-1/4em'>
                Sun Automotive Group
              </div>
              <div className='text-md sm:text-lg text-gray'>
                Automotive Advisors are the single point of contact for the entire sales process.
              </div>
            </div>
            <div className='relative mt-auto'>
              <img
                alt=''
                loading='lazy'
                src='https://media.roadster.com/stories/sunautogroup/thumb.jpg'
                className='block w-full'
              /> 
              <div className='transition-opacity opacity-0 group-hover:opacity-100 bg-darken4 absolute inset-0 flex justify-center items-center text-white'>
                <Button
                  as='span'
                  variant='outline'
                  minWidth={true}
                  children='View Story'
                />
              </div>
            </div>
          </GatsbyLink>
          <GatsbyLink
            to='/customer_stories/audirocklin'
            className='group w-full flex flex-col bg-white hover:shadow-lg transition-shadow'>
            <div className='p-2 sm:p-3'>
              <div className='font-semi-bold text-base sm:text-md mb-1/4em'>
                Audi Rocklin
              </div>
              <div className='text-md sm:text-lg text-gray'>
                Transparent customer experiences that lead to higher gross and CSI.
              </div>
            </div>
            <div className='relative mt-auto'>
              <img
                alt=''
                loading='lazy'
                src='https://media.roadster.com/stories/audirocklin/thumb.jpg'
                className='block w-full'
              /> 
              <div className='transition-opacity opacity-0 group-hover:opacity-100 bg-darken4 absolute inset-0 flex justify-center items-center text-white'>
                <Button
                  as='span'
                  variant='outline'
                  minWidth={true}
                  children='View Story'
                />
              </div>
            </div>
          </GatsbyLink>
          <GatsbyLink
            to='/customer_stories/longotoyota'
            className='group w-full flex flex-col bg-white hover:shadow-lg transition-shadow'>
            <div className='p-2 sm:p-3'>
              <div className='font-semi-bold text-base sm:text-md mb-1/4em'>
                Longo Toyota
              </div>
              <div className='text-md sm:text-lg text-gray'>
                Digitizing every step of the sales process.
              </div>
            </div>
            <div className='relative mt-auto'>
              <img
                alt=''
                loading='lazy'
                src='https://media.roadster.com/stories/longotoyota/thumb.jpg'
                className='block w-full'
              /> 
              <div className='transition-opacity opacity-0 group-hover:opacity-100 bg-darken4 absolute inset-0 flex justify-center items-center text-white'>
                <Button
                  as='span'
                  variant='outline'
                  minWidth={true}
                  children='View Story'
                />
              </div>
            </div>
          </GatsbyLink>
        </div>
      </Container>
    </div>
    <EsTeaser />
  </Layout>
)

export default TradeInAppraisalPage
